export const useImgAlt = (imageString: any) => {
  // Remove file extension
  const fileName = imageString.replace(/\.[^/.]+$/, '');

  // Replace periods, dashes, underscores, and other separator characters with spaces
  const separatorsReplaced = fileName.replace(/[.\-_]/g, ' ');

  // Convert camel/pascal case to spaces
  const camelCaseConverted = separatorsReplaced.replace(/([a-z])([A-Z])/g, '$1 $2');

  return camelCaseConverted;
};
